import styled from 'styled-components';

export const FootContainer = styled.div`
  background: #ededed;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #6e2b8b;
`;

export const FootStyle = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 130%;
  color: #9a9a9a;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 35px;
  }
`;

export const ParaText = styled.p`
  font-size: 13px;
  color: #9a9a9a;
  margin: 0 0 20px 0;
  padding: 0;
`;

export const LinkStyle = styled.a`
  margin-bottom: 10px;
  font-size: 12px;
  text-decoration: none;
  color: #944fb2;
  :hover {
    text-decoration: none;
    color: #6e2c8d;
  }
`;

export const ImageStyle = styled.img`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
`;
